import cn from "classnames";
import { Form, FormikHelpers, FormikProps } from "formik";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Button from "../../atoms/button/Button";
import Checkbox from "../../atoms/checkbox/Checkbox";
import Heading from "../../atoms/heading/Heading";
import { useLead } from "../../features/leads-funnel/application/lead-use-cases";
import { Locale } from "../../settings/countries";
import { Disease } from "../../settings/pet";
import { Events, track } from "../../utils/analytics";
import { experiments, isExperimentDefaultVariant } from "../../utils/experiments";
import {
  getCountryByLocale,
  shouldShowHowKnowUsQuestion,
} from "../../utils/locale-configuration-utils";
import { rollbar } from "../../utils/rollbar";
import PageStepForm from "../page-step-form/PageStepForm";
import * as styles from "./Diseases.module.scss";
import { HealthQuestionnaire } from "./domain/health-questionnaire";
import {
  useHealthQuestionnaire,
  useSetHealthQuestionnaire,
} from "./use-cases/health-questionnaire-use-cases";

interface DiseasesFormValues {
  diseases: Disease[];
}

const Diseases = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const lead = useLead();
  const healthQuestionnaire: HealthQuestionnaire = useHealthQuestionnaire();
  const setHealthQuestionnaire = useSetHealthQuestionnaire();
  const nextUrl: string =
    shouldShowHowKnowUsQuestion(getCountryByLocale(i18n.language as Locale)) &&
    isExperimentDefaultVariant(experiments.howKnowUsQuestion)
      ? `/${i18n.language}/onboarding/how_know_us/`
      : `/${i18n.language}/onboarding/email/`;
  const diseasesInfo = Object.values(Disease).map((diseaseKey) => ({
    key: diseaseKey,
    translationKey: `health_questionnaire.diseases.${diseaseKey}.label`,
  }));

  const handleCheckboxChange = (
    diseaseKey: Disease,
    props: FormikProps<DiseasesFormValues>,
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { checked } = event.target;
    const diseaseName = diseaseKey;
    const newDiseases = checked
      ? [...(props.values.diseases || []), diseaseName]
      : props.values.diseases?.filter((disease) => disease !== diseaseName) || [];

    props.setFieldValue("diseases", newDiseases);
  };

  const diseasesData = {
    initialValues: {
      diseases: healthQuestionnaire.diseases,
    },
    handleSubmit: (
      values: DiseasesFormValues,
      { setSubmitting }: FormikHelpers<DiseasesFormValues>
    ): void => {
      if (!setHealthQuestionnaire) {
        return;
      }

      setSubmitting(true);
      setHealthQuestionnaire({
        diseases: values.diseases,
      })
        .then((): void => {
          track(Events.FORM_ANSWERED, {
            healthQuestionnaire: {
              question: "Diseases",
              answer: values.diseases,
            },
          });

          void navigate(nextUrl);
        })
        .catch((err): void => {
          rollbar.warn("There was an error setting health questionnnaire.", err, {
            fingerprint: "health-questionnaire-errors",
            context: "health-questionnaire-diseases",
          });
          setSubmitting(false);
        });
    },
    children: (props: FormikProps<DiseasesFormValues>) => {
      const { isSubmitting, isValid } = props;

      return (
        <Form className={cn(styles.diseasesForm)}>
          <ul>
            {diseasesInfo.map((disease) => (
              <Checkbox
                key={disease.key}
                id={disease.key}
                name={disease.key}
                onChange={(event) => handleCheckboxChange(disease.key, props, event)}
                isChecked={props.values.diseases?.includes(disease.key) || false}
                adoptionClassName={styles.diseaseCheckbox}
              >
                {t(disease.translationKey)}
              </Checkbox>
            ))}
          </ul>

          <section className={styles.diseasesDisclaimer}>
            <Heading level={2}>{t("health_questionnaire.diseases.disclaimer.title")}</Heading>
            <ul>
              {[1, 2, 3].map((item) => (
                <li
                  key={item}
                  className={cn({
                    [styles.negative]: item === 3,
                  })}
                >
                  {t(`health_questionnaire.diseases.disclaimer.element.${item}.text`, {
                    petName: lead.petName,
                  })}
                </li>
              ))}
            </ul>
          </section>
          <Button type="submit" disabled={isSubmitting || !isValid} isLoading={isSubmitting}>
            {t("common.cta.next")}
          </Button>
        </Form>
      );
    },
  };

  return (
    <PageStepForm title={t("health_questionnaire.diseases.question")} formData={diseasesData} />
  );
};

export default Diseases;
